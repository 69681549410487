// Loader.js
import React from 'react';
import global from '../assets/scss/global.module.scss';

const Loader = () => {
  return (
    <div className={global.global_loader}></div>
  );
};

export default Loader;
