import React, { useState, useEffect } from "react";
import global from "../../../assets/scss/global.module.scss";
import useronboard from "./UserOnboard.module.scss";
import AadharAttempt from "../AadharAttempt";
import { ApiUrls } from "../../../utils/constants";
import fetcher from "../../../utils/fetcher";
import { getLocation } from "../../../utils/getLocation";
import Loader from "../../../utils/Loader";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { areNamesValid } from "../../../utils/utility";


const UserOnboardPanNumberStep = ({ panListener, userData }) => {
  const [panNumber, setPanNumber] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [showError, setShowError] = useState(false);
  const [unsuccessfulAttempt, setUnsuccessfulAttempt] = useState(false);
  const [aadharPanMismatch, setAadharPanMismatch] = useState(false);
  const [step2Confirmed, setStep2Confirmed] = useState(false);

  const [nameOnCard, setNameOnCard] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null); // Make sure it's null initially

  const [panDetails, setPanDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData?.completedStep >= 3 && userData?.leadPanDetails?.panNumber) {
      setStep2Confirmed(true);
      panListener();
    }
  }, [userData]);

  const verifyPan = async (panNumber) => {
    setLoading(true)
    const location = await getLocation();
    const formattedDOB = dateOfBirth ? dateOfBirth.toISOString().split('T')[0] : ""; // Format DOB to YYYY-MM-DD

    const payload = {
      userId: userData.userId,
      pan: panNumber,
      nameOnCard: nameOnCard,
      dateOfBirth: formattedDOB,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    const panRes = await fetcher.post(ApiUrls.LEAD_VERIFY_PAN_OTP, payload);
    if (panRes?.status === 200 && panRes.response.status === 200) {
      const panDetails = panRes.response.response.data.panDetails;
      const aadhaarName = userData.leadAadhaarDetails.fullName || userData.leadAadhaarDetails.name;
      if (!areNamesValid(aadhaarName, panDetails.nameOnCard)) {
        setAadharPanMismatch(true);
        return;
      }
      userData.leadPanDetails = panRes.response.response.data.panDetails
      setPanDetails(panDetails);
      setIsPanVerified(true);
      setShowError(false);
    } else {
      setIsPanVerified(false);
      setShowError(true);
      setAttempts((prevAttempts) => prevAttempts + 1);
      if (attempts + 1 >= 3) {
        setUnsuccessfulAttempt(true);
      }
    }
    setLoading(false)
  };

  const handleSubmit = () => {
   
    if (!isPanVerified) {
      verifyPan(panNumber);
    } else {
      updatePanData(panNumber);
    }
   
  };

  const handlePanInputChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
    setPanNumber(e.target.value);
    setShowError(false);
  };

  const handleNameChange = (e) => setNameOnCard(e.target.value);
  const handleDobChange = (newDate) => setDateOfBirth(newDate);

  const showCurrentPanView = () => {
    setStep2Confirmed((step2Confirmed) => !step2Confirmed);
  };

  const handleClose = () => {
    setUnsuccessfulAttempt(false);
    setAadharPanMismatch(false);
  };

  const updatePanData = async (panNumber) => {
    setLoading(true)
    const payload = {
      panNumber: panNumber,
      fullName: panDetails.nameOnCard,
      dateOfBirth: panDetails.dob,
      firstName: panDetails.name?.first,
      middleName: panDetails.name?.middle,
      lastName: panDetails.name?.last,
      aadhaarSeedingStatus: panDetails.aadhaarSeeding,
    };
    const panRes = await fetcher.post(ApiUrls.LEAD_SAVE_PAN_DATA, payload);
    if (panRes?.status === 200) {
      // Step 2: Confirm details
      setStep2Confirmed(true); // Hide Step 2 after confirmation
      panListener();
    } else if (panRes?.status !== 200) {
    } else {
    }
    setLoading(false)
  };

  return (
    <>
      <div className={`${useronboard.confirm_details} ${useronboard.confirm_details} ${global.mar_top_16}`}>
        {loading && <Loader />}
        <div className={`${global.row} ${useronboard.confirm_details_heading}`}>
          <div className={global.col_s_11}>
            <h3> <i className={useronboard.text_number}>B</i> Pan Verification </h3>
          </div>
          <div className={`${global.col_s_1} ${global.pos_rel}`}>
            <i
              className={
                step2Confirmed
                  ? global.verify_success_icon
                  : global.verification_icon
              }
              onClick={step2Confirmed ? showCurrentPanView : null}
            ></i>
          
          </div>
        </div>

        {!step2Confirmed && (
          <div>
            {/* Step 1 */}
            {!isPanVerified && !unsuccessfulAttempt && (
              <div className={useronboard.confirm_details_form}>
                <div className={global.row}>
                  <div className={global.col_s_3}>
                    <div
                      className={`${global.form_group} ${global.mar_top_16}`}
                    >
                      <label>Pan Number</label>
                      <input
                        className={global.form_control}
                        placeholder="Enter Your PAN Number"
                        type="text"
                        value={panNumber}
                        onChange={handlePanInputChange}
                      />
                      {showError && (
                        <div
                          className={`${global.error_message} ${global.text_right}`}
                        >
                          Incorrect PAN number entered. Please re-enter.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* DOB Field */}
                <div className={global.row}>
                  <div className={global.col_s_3}>
                    <div className={`${global.form_group}`}>
                      <label>Name</label>
                      <input
                        className={global.form_control}
                        placeholder="Enter Name on PAN Card"
                        type="text"
                        value={nameOnCard}
                        onChange={handleNameChange}
                      />
                    </div>
                  </div>
                </div>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className={global.row}>
                    <div className={global.col_s_3}>
                      <div className={`${global.form_group}`}>
                        <label>Date Of Birth</label>
                        <DatePicker
                          className={global.form_control}
                          placeholder="YYYY-MM-DD"
                          value={dateOfBirth}
                          onChange={handleDobChange}
                          renderInput={(params) => <input {...params} />}
                        />
                      </div>
                    </div>
                  </div>
                </LocalizationProvider>

                <div className={`${global.row} ${global.mar_top_16}`}>
                  <div className={global.col_s_3}>
                    <input
                      type="submit"
                      className={`${global.submit_primary_btn} ${useronboard.width_pan_btn}`}
                      value="Submit"
                      onClick={handleSubmit}
                      disabled={!panNumber} 
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Step 2: Show after PAN is verified */}
            {isPanVerified && !unsuccessfulAttempt && (
              <div>
                <div className={useronboard.confirm_details_form}>
                  <div className={global.row}>
                    <div className={global.col_s_5}>
                      <span
                        className={` ${global.success_message} ${global.pos_rel}`}
                      >
                        <i
                          className={` ${global.verify_success_icon} ${global.pos_rel_tr}`}
                        ></i>{" "}
                        PAN Verified
                      </span>
                    </div>
                  </div>

                  <div className={`${global.row} ${global.mar_top_16}`}>
                    <div className={global.col_s_9}>
                      <div className={global.row}>
                        <div className={global.col_s_2}>Name :</div>
                        <div className={global.col_s_9}>
                          {panDetails.nameOnCard}
                        </div>
                      </div>

                      <div
                        className={`${global.row} ${global.mar_top_8} ${global.mar_bottom_16}`}
                      >
                        <div className={global.col_s_2}>Seeding Status :</div>
                        <div className={global.col_s_9}>
                          {panDetails.aadhaarSeeding === 1 ? (
                            <span>Yes</span>
                          ) : (
                            <span className={useronboard.seeding_status_not}>
                              No
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {panDetails.aadhaarSeeding !== 1 ? (
                            <div className={`${global.row} ${global.mar_top_16}`}>
                            <div className={global.col_s_6}>
                              <div className={global.info_message_bt}>
                                Your PAN card is not linked to your Aadhaar card. Tax
                                Deducted at Source (TDS) on your income may be deducted
                                at a higher rate of 20%.
                              </div>
                            </div>
                          </div>
                          ) : (
                            null
                          )}

                  
                </div>

                <div
                  className={`{ ${useronboard.submit_text_bg_} { ${global.mar_top_16}`}
                >
                  <div className={global.row}>
                    <div className={global.col_s_2}>
                      {/* <input type='submit' className={`${global.submit_seconday_btn} ${global.display_inline_blk}`} value="Submit" /> */}
                      <input
                        type="submit"
                        onClick={handleSubmit}
                        className={`${global.submit_primary_btn} ${global.display_inline_blk}`}
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

       
            {(unsuccessfulAttempt || aadharPanMismatch) && (
              <AadharAttempt
                unsuccessfulAttempt={unsuccessfulAttempt}
                aadharPanMismatch={aadharPanMismatch}
                onClose={handleClose} // Pass the close handler to AadharAttempt
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserOnboardPanNumberStep;

