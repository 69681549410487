import React, { useEffect, useState } from 'react';
import global from '../../assets/scss/global.module.scss'
import UserRegistrations from './UserRegistrations.module.scss';
import logo from '../../assets/images/tramo-logo.svg';
import Captcha from './Captcha'
import MobileOTP from './MobileOTP';
import EmailOTP from './EmailOTP';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiUrls, PageUrls } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import fetcher from '../../utils/fetcher';
import Loader from '../../utils/Loader'

const UserRegistration = () => {

  const navigate = useNavigate();
  const [showMobileDialog, setShowMobileDialog] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);

  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);

  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(""); // State for storing API error messages

  const [message, setMessage] = useState("");
  const maxChars = 500; // Set maximum character limit


  const [leadUser, setLeadUser] = useState({});
  const [snackbar, setSnackbar] = useState({
    show: false,
    status: "",
    message: "",
  });

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const toggleSnackbar = (value) => {
    setSnackbar(value);
  };

  const [formData, setFormData] = useState({
    companyName: '',
    authorizedPersonName: '',
    mobile: '',
    email: '',
    companyWebsite: '',
    message: '',
    captcha: isCaptchaValidated
  });

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Business name is required'),
    authorizedPersonName: Yup.string().required('Authorized person name is required'),
    mobile: Yup.string().required('Mobile is required'),
    email: Yup.string().email('Invalid email address').required('Email address is required'),
    companyWebsite: Yup.string().url('Invalid URL').optional(),
    message: Yup.string().max(512, 'Message must be 512 characters or less').optional(),
    captcha: Yup.boolean()
      .oneOf([true], 'CAPTCHA validation is required')
      .required('CAPTCHA validation is required')
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true); // Show loader when API call starts
      const res = await fetcher.post(ApiUrls.LEAD_BASIC_REGISTRATION, values);
      if (res?.status === 200 && res?.response?.token) {
          localStorage.setItem('auth', JSON.stringify(res?.response));
          setLeadUser(res.response)
          const resOtp = await fetcher.get(`${ApiUrls.LEAD_GENRATE_OTP}/${values.email}`);
        if (resOtp?.status === 200) {
          setShowEmailDialog(true);
        } else {
          alert(resOtp?.message);
        }
      } else if (res?.status === 400) {
        // If status is 400, extract the error message and show it
        setApiErrorMessage(res?.message || "Something went wrong. Please try again.");
      }
    } catch (err) {
      console.log(err);
      setApiErrorMessage("An error occurred while processing your request.");
    } finally {
      setLoading(false); // Hide loader after API call ends
    }
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,  // Allows form to reinitialize when formData changes
  });

  const handleMobileOTPDialog = () => {

    setIsMobileVerified(true)
    setShowMobileDialog(false)
   
  };

  const handleEmailOTPDialog = async () => {
    //both otp are verified 
    setIsEmailVerified(true)
    setShowEmailDialog(false);
    const resOtp = await fetcher.get(`${ApiUrls.LEAD_GENRATE_OTP}/${formik.values.mobile}`);
    if (resOtp?.status === 200) {
      setShowMobileDialog(true);
    } else {
      alert(resOtp?.message);
    }
  };
  const handleCaptchaValidation = (isValid) => {
    setIsCaptchaValidated(isValid);
  };
  useEffect(() => {
    if (isMobileVerified && isEmailVerified) {
      navigate(PageUrls.USER_ONBOARD);
    }
    formik.setFieldValue('captcha', isCaptchaValidated);
  }, [isMobileVerified, isEmailVerified, isCaptchaValidated]);

  useEffect(() => {
    localStorage.removeItem('auth');
  }, []);

  return (
    <div className={global.container}>
      {loading && <Loader />}
      <div className={global.row}>
        <div className={global.col_s_6}>
          <div className={UserRegistrations.left_form_container}>
            <h1><img src={logo} alt="Tramo" /> </h1>
            <div className={UserRegistrations.welcome_screen}>
              Welcome
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={`${global.form_group} ${global.mar_top_16}`}>
                <label>Business Name</label>
                <input
                  className={global.form_control}
                  name="companyName"
                  placeholder="Enter your Entity/Company Name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.companyName}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    {formik.errors.companyName}
                  </div>
                )}
              </div>
              <div className={global.form_group}>
                <label>Authorised Person Name</label>
                <input className={global.form_control}
                  name='authorizedPersonName'
                  placeholder='Enter your Name'
                  type='text'
                  onChange={formik.handleChange}
                  value={formik.values.authorizedPersonName} />
                {formik.touched.authorizedPersonName && formik.errors.authorizedPersonName && (
                  <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>{formik.errors.authorizedPersonName}</div>
                  )}
              </div>
              <div className={global.form_group}>
                <label>Mobile Number</label>
                <input
                  className={`${global.form_control}`}
                  name="mobile"
                  placeholder="Enter your Mobile Number"
                  type="tel"
                  onChange={formik.handleChange}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.mobile}
                />
                {formik.touched.mobile && formik.errors.mobile && !apiErrorMessage && (
                  <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                    {formik.errors.mobile}
                  </div>
                )}
                {apiErrorMessage && (
                  <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                    {apiErrorMessage}
                  </div>
                )}
              </div>
              <div className={global.form_group}>
                <label>Email Address</label>
                <input
                  className={global.form_control}
                  name="email"
                  placeholder="Enter your Email ID"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && !apiErrorMessage && (
                  <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                    {formik.errors.email}
                  </div>
                )}
                {apiErrorMessage && (
                  <div className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}>
                    {apiErrorMessage}
                  </div>
                )}
              </div>
              <div className={global.form_group}>
                <label>Company's Website URL (Optional) </label>
                <input
                  className={global.form_control}
                  name="companyWebsite"
                  placeholder="Enter your Company’s Website URL"
                  type="text"
                  onChange={(e) => {
                    let url = e.target.value;
                    if (url && !/^https?:\/\//i.test(url)) {
                      url = `https://${url}`;
                    }
                    formik.setFieldValue("companyWebsite", url);
                  }}
                  value={formik.values.companyWebsite}
                />
                {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    {formik.errors.companyWebsite}
                  </div>
                )}
              </div>
              <div className={global.form_group}>
                <label className={`${global.d_flex} ${global.d_flex_l_r}`}>
                  <span>Write a message to us (Optional)</span>{" "}
                  <span className={global.gray_text_color}>
                    {message.length} / {maxChars} characters words left
                  </span>
                </label>
                <textarea
                  className={global.form_control}
                  name="message"
                  placeholder="Message..."
                  type="text"
                  onChange={handleChange}
                  maxLength={maxChars}
                  rows="4"
                  value={message}
                />
                {formik.touched.message && formik.errors.message && (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    {formik.errors.message}
                  </div>
                )}
              </div>
              <div className={global.form_group}>
                <Captcha onValidate={handleCaptchaValidation} />
                {formik.touched.captcha && formik.errors.captcha && (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    {formik.errors.captcha}
                  </div>
                )}
              </div>

              {/* Secondary Button */}
              {!formik.isValid && (
                <div className={global.form_group}>
                  <input
                    type="submit"
                    value="Submit"
                    className={global.submit_seconday_btn}
                  />
                </div>
              )}

              {/* Primary Button - Visible only when the form is valid */}
              {formik.isValid && (
                <div className={global.form_group}>
                  <input
                    type="submit"
                    value="Submit"
                    className={global.submit_primary_btn}
                  />
                </div>
              )}
            </form>
            <div className={`${global.form_group} ${global.text_center}`}>
              Already have an account? <a href="#">Sign in</a>
            </div>
          </div>
        </div>
        <div className={global.col_s_6}>
          <div className={UserRegistrations.user_registration_bg}>
            <div className={UserRegistrations.right_img_bottom}>
              <p>
                Transforming the way under banked communities manage money with
                intelligent banking.
              </p>
            </div>
          </div>
        </div>
      </div>

      {showMobileDialog && (
        <MobileOTP mobileOTPEvent={handleMobileOTPDialog} leadUser={leadUser} />
      )}

      {showEmailDialog && (
        <EmailOTP emailOTPEvent={handleEmailOTPDialog} leadUser={leadUser} />
      )}
    </div>
  );
};
export default UserRegistration;
