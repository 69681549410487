import React, { useEffect, useState } from 'react';
import global from '../../../assets/scss/global.module.scss';
import useronboard from './UserOnboard.module.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ParticipantsThanks from './ParticipantsThanks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import fetcher from '../../../utils/fetcher';
import { ApiUrls, DepartmentType, LeadSteps } from '../../../utils/constants';
import Loader from '../../../utils/Loader';
import { Modal, TextField, Button } from '@mui/material';

const DepartmentInformationFinal = ({ nextStep, userData }) => {
    const [showParticipantsThanks, setshowParticipantsThanks] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState({
        operation: [],
        finance: [],
        it: []
    });
    const [showForm, setShowForm] = useState({
        operation: false,
        finance: false,
        it: false
    });

    // Modal state and editing participant data
    const [openModal, setOpenModal] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);

    const handleAddClick = (dept) => {
        setShowForm((prev) => ({ ...prev, [dept]: true }));
    };

    const handleSubmitForm = async () => {
        setLoading(true);
        const resOtp = await fetcher.get(`${ApiUrls.LEAD_UPDATE_REGISTER_STEP}/${LeadSteps.department}`);
        if (resOtp?.status === 200) {
            setshowParticipantsThanks(true);
        } else {
            alert(resOtp?.message);
        }
        setLoading(false);
    };

    const onSubmitListener = () => {
        console.log('Lead Data Submitted Successfully', 'Go To Home');
    };

    const participantSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
            .required('Phone number is required'),
    });

    useEffect(() => {
        if (userData.leadDepartmentParticipants && userData.leadDepartmentParticipants.length > 0) {
            setParticipants({
                operation: userData.leadDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.operation),
                finance: userData.leadDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.finance),
                it: userData.leadDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.it)
            });
        }
    }, []);

    useEffect(() => {
        setIsSubmitEnabled(
            participants.operation.length > 0 &&
            participants.finance.length > 0 &&
            participants.it.length > 0
        );
    }, [participants]);

    const DepartmentParticipantForm = ({ dept, participantData }) => {
        const formik = useFormik({
            initialValues: {
                id:participantData?.id || null ,
                departmentType: participantData?.departmentType || DepartmentType[dept],
                name: participantData?.name || '',
                email: participantData?.email || '',
                phone: participantData?.phone || '',
            },
            validationSchema: participantSchema,
            onSubmit: (values) => {
                saveParticipantData(dept, values);
            },
        });

        const saveParticipantData = async (dept, values) => {
            setLoading(true);
            const res = await fetcher.post(ApiUrls.LEAD_SAVE_DEPT_PARTICIPANTS, values);
            if (res?.status === 200) {
                setParticipants((prev) => ({
                    ...prev,
                    [dept]: prev[dept].map(participant =>
                        participant.id === values.id ? { ...participant, ...values } : participant
                    )
                }));
                setOpenModal(false);
            }
            setLoading(false);
        };

        return (
            <form onSubmit={formik.handleSubmit}>
                <div className={`${global.row} ${global.mar_top_16}`}>
                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person Name</label>
                            <input
                                className={global.form_control}
                                placeholder="Enter Name"
                                type="text"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.name && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.name}</div>}
                        </div>
                    </div>
                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person E-mail</label>
                            <input
                                className={global.form_control}
                                placeholder="Enter E-mail"
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.email && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.email}</div>}
                        </div>
                    </div>
                </div>
                <div className={global.row}>
                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person Phone</label>
                            <input
                                className={global.form_control}
                                placeholder="Phone Number"
                                type="tel"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.phone && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.phone}</div>}
                        </div>
                    </div>
                    <div className={`${global.col_s_6} ${global.mar_top_28}`}>
                        <input
                            type="submit"
                            className={`${global.submit_primary_btn} ${global.display_inline_blk}`}
                            value="Submit"
                        />
                    </div>
                </div>
            </form>
        );
    };

    // Open Edit Modal
    const handleEditClick = (participant) => {
        setSelectedParticipant(participant);
        setOpenModal(true);
    };

    return (
        <>
            <div className="card">
                <Accordion activeIndex={0}>
                    {['operation', 'finance', 'it'].map((dept, idx) => (
                        <AccordionTab key={idx} header={<div className="accordion-header"><span>{`${dept.charAt(0).toUpperCase() + dept.slice(1)} Department`}</span></div>}>
                            {participants[dept].length > 0 ? (
                                <>
                                    <div className={useronboard.no_participants_message}>
                                        <input
                                            title="Add New Participants"
                                            type="submit"
                                            className={useronboard.add_bank_account}
                                            value="+ Add"
                                            onClick={() => handleAddClick(dept)}
                                            style={{ marginBottom: '1.25rem' }}
                                        />
                                    </div>
                                    {participants[dept].map((participant, index) => (
                                        <div key={participant.id} className={useronboard.edit_participants}>
                                            <div className={`${global.d_flex} ${global.d_flex_align_item} ${global.d_flex_l_r}`}>
                                                <ul className={global.d_flex}>
                                                    <li className={global.d_flex}>
                                                        <i className={useronboard.participants_number}>
                                                            {(index + 1).toString().padStart(2, '0')}
                                                        </i>
                                                        <i className={useronboard.first_character}>{participant.name}</i>
                                                    </li>
                                                    <li><span className={useronboard.participants_full_name}>{participant.name}</span></li>
                                                </ul>
                                                <span>
                                                    <a href="#" onClick={() => handleEditClick(participant)}>
                                                        <i className={global.edit_icon}></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className={useronboard.no_participants_message}>
                                    <input
                                        title="Add New Participants"
                                        type="submit"
                                        className={useronboard.add_bank_account}
                                        value="+ Add"
                                        onClick={() => handleAddClick(dept)}
                                    />
                                </div>
                            )}

                            {/* Add New Participant Form */}
                            {showForm[dept] && <DepartmentParticipantForm dept={dept} />}
                        </AccordionTab>
                    ))}
                </Accordion>
                <div className={useronboard.submit_text_bg_}>
                    <div className={global.row}>
                        <div className={global.col_s_2}>
                            <input
                                type="submit"
                                onClick={handleSubmitForm}
                                className={isSubmitEnabled ? `${global.submit_primary_btn} ${global.display_inline_blk}` : global.submit_seconday_btn}
                                value="Submit"
                                disabled={!isSubmitEnabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showParticipantsThanks && <ParticipantsThanks onClose={onSubmitListener} />}
            {loading && <Loader />}

            {/* Edit Participant Modal */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiModal-paper': {
                        width: '600px',  // Adjust modal width
                        maxWidth: '90%', // Max width to be responsive
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: 24,
                    }
                }}
            >
                <div className="modal-content">
                    <DepartmentParticipantForm dept="operation" participantData={selectedParticipant} />
                </div>
            </Modal>
        </>
    );
};

export default DepartmentInformationFinal;
