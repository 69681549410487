import React, { useState, useEffect, useRef  } from "react";
import global from '../../assets/scss/global.module.scss'
import capcha from '../../assets/images/capcha-icon.svg';

const Captcha = ({ onValidate }) => {
  const [captchaText, setCaptchaText] = useState("");
  const [inputValue, setInputValue] = useState("");

  const generateCaptcha = () => {
    const randomString = Math.random().toString(36).substring(2, 8); 
    setCaptchaText(randomString);
    onValidate(false);
  };

  useEffect(() => {
    generateCaptcha(); 
  }, []);

  const handleInputChange = (e) => {
    if (e.target.value.toLowerCase() === captchaText.toLowerCase()) {
      onValidate(true);
    } else {
      onValidate(false);
    }
  };

  return (
        <>
         <div className={global.row}>
             <div className={ `${global.col_s_4} ${global.capcha_font_size}`} >
             <label>Enter Captcha Code</label>
                <div className={global.otp_input_box}>
                {captchaText} 
                <button onClick={generateCaptcha} style={{ background: "none", border: "none", cursor: "pointer" }} >
                <img src={capcha} alt="refresh icon" />
                 </button></div>
            </div>
            <div className={global.col_s_8}>
            <label>&nbsp;</label>
            <input type="text" className={global.form_control} onChange={handleInputChange}  placeholder="Enter CAPTCHA" />  
           </div>
        </div>
      </>
  );
};

export default Captcha;
