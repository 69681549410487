import React from 'react';
import global from '../../../assets/scss/global.module.scss'
import UserRegistrations from '../UserRegistrations.module.scss';


const ParticipantsThanks = () => {

    return (
        <div className={global.overlay}>
            <div className={`${UserRegistrations.opt_screen} ${UserRegistrations.opt_screen_large} ${UserRegistrations.opt_screen_sm}`}>
                <div className={`${global.card_content} ${global.text_center}`}>
                    <div className={UserRegistrations.unsuccessful}>
                        <div className={global.text_center}>
                            <i className={global.successful_icon}></i>
                        </div>
                        <h2>Thank you !</h2>
                        <div className={global.thanks_messsage_blue}>
                            For submitting Your Documents
                        </div>
                        <div className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}>
                            Your request is under review. We will update you shortly !
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParticipantsThanks