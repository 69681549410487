import React, { useEffect,useState } from 'react';
import person_image from '../../../assets/images/image14.png';
import global from '../../../assets/scss/global.module.scss';
import useronboard from './UserOnboard.module.scss';
import { ApiUrls } from '../../../utils/constants';
import fetcher from '../../../utils/fetcher';
import { getLocation } from '../../../utils/getLocation';
import Loader from '../../../utils/Loader';

const UserAddharCard = ({ aadharListener , userData }) => {
 // const [userData, setUserData] = useState(null); // To store the user data
  const [otpAadharData, setOtpAadharData] = useState(null); // State to store OTP response data
  const [aadhaarDetails, setAadhaarDetails] = useState({});
  const [aadharError, setAadharError] = useState(""); // State to track the error message
  const [aadhaar, setAadhaar] = useState(new Array(12).fill("")); // 12-digit Aadhaar input
  const [step, setStep] = useState(1); // Step 1: Enter Aadhaar, Step 2: Enter OTP, Step 3: Aadhaar Verified

  const [otp, setOTP] = useState(new Array(6).fill("")); // 12-digit Aadhaar input
  const [otpError, setOTPError] = useState("");
  const [buttonText, setButtonText] = useState("Send OTP");
  const [step3Confirmed, setStep3Confirmed] = useState(false);

  const [timer, setTimer] = useState(59); // Timer state (59 seconds)
  const [showResendBtn, setShowResendBtn] = useState(false); // Show/hide resend button
  // Render the 12-digit Aadhaar number as a string
  const aadhaarNumber = aadhaar.join('');
  const aadhaarOTP = otp.join('');
  const [aadhaarImageUrl, setAadhaarImageUrl] = useState({});

  const [loading, setLoading] = useState(false); // Loader state


  

  // Start countdown when the OTP is sent (Step 2)
  useEffect(() => {
    if (step === 2 && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowResendBtn(true); // Show the resend button when the timer reaches 0
    }
  }, [step, timer]);


  const handleInputChange = (e, index) => {
    const value = e.target.value;
    
    if (/^\d?$/.test(value)) {  // Allow only digits or empty (for clearing)
      const newAadhaar = [...aadhaar];
      newAadhaar[index] = value;
      setAadhaar(newAadhaar);
      setAadharError("");
  
      // Automatically move to the next input if a digit is entered
      if (value && index < aadhaar.length - 1) {
        document.getElementById(`aadhaar-input-${index + 1}`).focus();
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newAadhaar = [...aadhaar];
      
      // Clear the current field if there's content, otherwise move to the previous field
      if (newAadhaar[index]) {
        newAadhaar[index] = "";
        setAadhaar(newAadhaar);
      } else if (index > 0) {
        document.getElementById(`aadhaar-input-${index - 1}`).focus();
      }
    }
  };
  

 

  // Change the button text dynamically based on the step
  useEffect(() => {
   
    if (step === 1) {
      setButtonText("Send OTP");
    } else if (step === 2) {
      setButtonText("Verify");
    }
    if (userData?.completedStep >= 2 && userData?.leadAadhaarDetails?.aadharNumber) {
      setStep3Confirmed(true);
      openPanView();
      setStep(3);
    }
  }, [step, userData]);
  const genrateAadharOTP = async (aadhaarNumber) => {
    setAadharError("");
    setLoading(true)
    const location = await getLocation();
    const payload = {
      aadhaarNumber: aadhaarNumber,  
      latitude: location.latitude,  
      longitude: location.longitude, 
      consent: "Y" 
    };
    const resOtp = await fetcher.post(ApiUrls.LEAD_GENRATE_AADHAR_OTP, payload);
    setLoading(false)
    if (resOtp?.status === 200 && resOtp?.response.status === 200) {
      const  data = resOtp.response.response.data;
      // Save the OTP data in state
      setOtpAadharData({
        otpReferenceID: data.otpReferenceID,
       // aadhaarNumber: aadhaarNumber,
        status: resOtp.response.status
      });
    // Move to OTP step
    setStep(2);
    setTimer(59); // Reset timer to 59 seconds
    setShowResendBtn(false); // Hide resend button while timer is active
    } else {
      setAadharError(resOtp?.response?.message || resOtp?.message);
    }
  }
  const verifyAadharOTP = async (otpEnter) => {
    setOTPError("")
    setLoading(true)
    const location = await getLocation();

    const payload = {
      otp: otpEnter,
      otpReferenceID:otpAadharData.otpReferenceID,
      latitude: location.latitude,  
      longitude: location.longitude,
      consent: "Y" 
    };
    const aadhaarRes = await fetcher.post(ApiUrls.LEAD_VERIFY_AADHAR_OTP, payload);
    setLoading(false)
    if (aadhaarRes?.status === 200 && aadhaarRes?.response.status === 200) {
      userData.leadAadhaarDetails = aadhaarRes.response.response.data;
      setAadhaarDetails(aadhaarRes.response.response.data);
      const imageUrl = `data:image/jpeg;base64,${aadhaarRes.response.response.data.profilePic}`;
      setAadhaarImageUrl(imageUrl);
      setStep(3);
    } else {
      setOTPError(aadhaarRes?.response?.message || aadhaarRes?.message);
    }
  }

    // Resend OTP function
    const resendOTP = () => {
      genrateAadharOTP(aadhaarNumber);
    }
  // Button click handler for "Send OTP" or "Verify"
  const handleButtonClick = () => {
    if (step === 1) {
      if (aadhaarNumber.length !== 12) {
        setAadharError("Incorrect Aadhaar number entered. Please re-enter.");
        return;
      }
      if (!isValidAadhaarNumber(aadhaarNumber)) {
        //alert('Invalid Aadhaar Number');
        setAadharError("Invalid Aadhaar Number");
        return;
      }
     genrateAadharOTP(aadhaarNumber)
    } else if (step === 2) {
        // If the button text is "Verify", open the PAN view
        // Move to Aadhaar details step
        if (otp.length !== 6) {
          setOTPError("Please enter OTP");
          return;
        }
        verifyAadharOTP(aadhaarOTP);
    } else if (step === 3) {
        setStep3Confirmed(true); // Hide Step 2 after confirmation
        openPanView();  // Call openPanView function when button text is "Verify"
    }
  };
  // Handle changing Aadhaar number (back to step 1)
  const handleChangeAadhaar = () => {
    setStep(1);// Back to Aadhaar input step
  setAadhaar(Array(12).fill('')); // Reset Aadhaar number
  };
 

   //OTP Handling
    // Handle otp input change for Aadhaar digits
  // const handleOTPInputChange = (e, index) => {


  const handleOTPInputChange = (e, index) => {
    const value = e.target.value;
    
    if (/^\d?$/.test(value)) {  // Allow only digits or empty (for clearing)
      const newOTP = [...otp];
     newOTP[index] = value;
     setOTP(newOTP);
    setOTPError("");
      // Automatically move to the next input if a digit is entered
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  
  const handleOTPKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOTP = [...otp];
      
      // Clear the current field if there's content, otherwise move to the previous field
      if (newOTP[index]) {
        newOTP[index] = "";
        setOTP(newOTP);
      } else if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const openPanView = () => {
    aadharListener()
  };
  const showCurrentAadharView = () => {
    //if(step3Confirmed){ setStep3Confirmed(false); }else{ setStep3Confirmed(true); }
    setStep3Confirmed(step3Confirmed => !step3Confirmed);

  }

  const isValidAadhaarNumber = (aadhaarNumber) => {
    const regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
  
    if (!aadhaarNumber) {
      return false;
    }
  
    return regex.test(aadhaarNumber);
  };
  return (
    <>
      <div className={useronboard.confirm_details}>
        <div className={`${global.row} ${useronboard.confirm_details_heading} ${global.mobile_d_flex}`}>
        {loading && <Loader />} 
          <div className={global.col_11}>
            <h3>
              <i className={useronboard.text_number}>A</i> Aadhaar Verification
            </h3>

          </div>
          <div className={`${global.col_1} ${global.pos_rel}`}>
            <i className={step3Confirmed ? global.verify_success_icon : global.verification_icon} onClick={step3Confirmed ? showCurrentAadharView : null}></i>
             {/* {step3Confirmed && <i className={global.verify_success_icon}></i>} */}
          </div>
        </div>
        {!step3Confirmed && (<div>
        <div className={useronboard.confirm_details_form}>
          {step === 1 && (
            <>
              {/* Aadhaar Number Input */}
              <div className={global.row}>
                <div className={global.col_s_6}>
                  <div className={`${global.form_group} ${global.mar_top_16} ${global.mobile_hr_scroll}`}>
                    <label>Enter your Aadhaar Number</label>
                    <div className={global.d_flex}>
                      {aadhaar.map((digit, index) => (
                        <input
                          key={index}
                          id={`aadhaar-input-${index}`}
                          className={` ${global.form_control} ${global.form_control_otp} ${(index + 1) % 4 === 0 ? global.mar_right_16 : global.mar_right_4}`}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleInputChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      ))}
                    </div>
                    {aadharError && (
                      <div className={`${global.error_message} ${global.text_right} ${useronboard.user_aadharcard_right_error}`}>
                        {aadharError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={useronboard.otp_message_}>
                OTP will be sent to your Aadhaar Linked Phone number.
              </div>
            </>
          )}

          {step === 2 && (
            <>
              {/* Aadhaar OTP Screen */}
              <div className={global.row}>
                <div className={global.col_s_5}>
                  <div className={`${global.form_group} ${global.mar_top_16}`}>
                    <div className={` ${useronboard.aadhar_otp_number} ${global.mar_bottom_16}`}>
                    <span className={`${useronboard.aadhar_number} ${global.mar_right_16} ${useronboard.otp_bold}`}>
                    {aadhaarNumber.replace(/(.{4})(.{4})(.{4})/, '$1 $2 $3')}</span>
                    <a href={void(0)} onClick={handleChangeAadhaar} className={useronboard.change_aadhar_number}>
                      Change Aadhaar Number
                    </a>
                  </div>
                    <label className={global.mar_bottom_16}>
                      Enter OTP sent to Aadhaar linked Number.
                    </label>
                    <div className={global.d_flex}>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          className={` ${global.form_control} ${global.form_control_otp} ${global.mar_right_4}`}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleOTPInputChange(e, index)}
                          onKeyDown={(e) => handleOTPKeyDown(e, index)}
                        />
                      ))}
                    </div>
                    {otpError && (
                      <div className={`${global.error_message} ${global.text_right}`}>
                        {otpError}
                      </div>
                    )}
                    <div className={useronboard.otp_message_}>
                      {/* Didn’t receive any OTP? <a href={void(0)}> Resend in 23s </a>
                      <a href={void(0)} className={useronboard.resend_otp}>Resend OTP</a> */}
                         <>
      {/* <div className={useronboard.confirm_details}> */}
        {/* Aadhaar Verification UI */}
        {step === 2 && (
          <>
            <div>
              {/* Display timer and resend button */}
              <div>
                {timer > 0 ? (
                  <span>Resend OTP in {timer} seconds</span>
                ) : (
                  showResendBtn && <a href={void(0)} className={useronboard.resend_otp} onClick={resendOTP}>Resend OTP</a>
                )}
              </div>
            </div>
          </>
        )}
      {/* </div> */}
    </>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              {/* Aadhaar Details View || person_image*/}
              <div className={global.row}>
            <div className={global.col_s_5}>
              <span className={global.success_message}> <i className={global.verify_success_icon}></i> Aadhar Verified</span>
            </div>
          </div>

              <div className={global.image_preview_details}>
                <div className={`${global.row} ${global.mar_top_16}`}>
                  <div className={global.col_s_3}>
                    <div className={global.images_bg}>
                      <img src={aadhaarImageUrl } alt="Person" />
                    </div>
                  </div>
                  <div className={global.col_s_9}>
                    <div className={global.row}>
                      <div className={global.col_s_3}>Name:</div>
                      <div className={global.col_s_9}>{aadhaarDetails.fullName}</div>
                    </div>

                    <div className={`${global.row} ${global.mar_top_8}`}>
                      <div className={global.col_s_3}>DOB:</div>
                      <div className={global.col_s_9}>{aadhaarDetails.dateOfBirth}</div>
                    </div>

                    <div className={`${global.row} ${global.mar_top_8}`}>
                      <div className={global.col_s_3}>Gender:</div>
                      <div className={global.col_s_9}>{aadhaarDetails.gender}</div>
                    </div>

                    <div className={`${global.row} ${global.mar_top_8}`}>
                      <div className={global.col_s_3}>Address:</div>
                      <div className={global.col_s_9}>
  {[
    aadhaarDetails?.address?.careof,
    aadhaarDetails?.address?.house,
    aadhaarDetails?.address?.landmark,
    aadhaarDetails?.address?.street,
    aadhaarDetails?.address?.postOffice,
    aadhaarDetails?.address?.pin,
    aadhaarDetails?.address?.district,
    
    aadhaarDetails?.address?.state,
    aadhaarDetails?.address?.country
  ]
    .filter(Boolean) // Removes any `undefined` or empty values
    .join(" ")} 
</div>

                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Button */}
        <div className={useronboard.submit_text_bg_}>
          <input
            type="submit"
            className={`${global.submit_primary_btn} ${global.display_inline_blk} ${useronboard.width_pan_btn}}`}
            value={step === 1 ? "Send OTP" : step === 2 ? "Verify" : "Next"}
            onClick={handleButtonClick}
          />
        </div>
        </div> )}
      </div>
    </>
  );
};

export default UserAddharCard;
