import React, { useState } from 'react';
import global from '../../../assets/scss/global.module.scss'
import UserRegistrations from '../UserRegistrations.module.scss';
import useronboard from './UserOnboard.module.scss';


const WithOutGstNumber = ({ businssDetails, onClose }) => {
    const [isDisabled, setIsDisabled] = useState(true);

    const toggleDisable = () => {
        setIsDisabled(!isDisabled);
    };
    const handleSubmit = () => {
        // Hide the component on submit
        onClose();
    };
    
    return (
        <div className={global.overlay}>
            <div className={`${UserRegistrations.opt_screen} ${UserRegistrations.opt_screen_large}`}>
                <div className={UserRegistrations.opt_screen_large_pad}>
                    <div className={global.row}>
                        <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                                <label>Business Name</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.businessName} readOnly />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Constitution type</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.constitutionType} readOnly type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Business Incorporation Date</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.incorporationDate} readOnly type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Business PAN</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.businessPAN} readOnly type='text' />
                            </div>
                        </div>


                        <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                                <label>Business Registered Address 1</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.address1} readOnly type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Business Registered Address 2</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.address2} readOnly type='text' />
                            </div>

                            <div className={global.row}>
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>

                                        <label>Pin Code</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.pinCode} readOnly type='text' />

                                    </div>
                                </div>
                            </div>

                            <div className={global.row}>
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>
                                        <label>City/Village/Town</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} value={businssDetails.city} readOnly type='text' />
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>State jurisdiction  </label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} value={businssDetails.stateJurisdiction} readOnly type='text' />
                                    </div>
                                </div>
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>
                                        <label>District</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} value={businssDetails.district} readOnly type='text' />
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>Country</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} value={businssDetails.country} readOnly type='text' />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>  </div>
                <div className={useronboard.submit_text_bg}>
                    <input type='submit' onClick={handleSubmit} className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Submit" />
                </div>
            </div>


        </div>
    )
}

export default WithOutGstNumber