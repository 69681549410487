import React, { useEffect, useState } from 'react';
import global from '../../../assets/scss/global.module.scss';
import useronboard from './UserOnboard.module.scss';
import banklogo from '../../../assets/images/hdfcbanklogo.png';
import formmodule from '../../../assets/scss/form.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import fetcher from '../../../utils/fetcher';
import { ApiUrls, LeadSteps } from '../../../utils/constants';
import { getLocation } from '../../../utils/getLocation';
import Loader from '../../../utils/Loader';

const AddBankAccount = ({nextStep , userData}) => {
  const [showAddBankView, setShowAddBankView] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loading ,setLoading] = useState(false)
  const [newAccount, setNewAccount] = useState({
        bankName: '',
        accountNumber: '',
        ifsc: '',
        branchName: '',
        accountType: '',
        holderName: '',
    isPrimary: false,
        holderType: 'Entity',
        isValid: false
  });
  const [primaryAccountIndex, setPrimaryAccountIndex] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const initialSchema = Yup.object({
        bankName: Yup.string().required('Bank Name is required'),
        accountNumber: Yup.string().required('Account Number is required'),
        ifsc: Yup.string().required('IFSC Code is required'),
  });

  const bankDetailsSchema = initialSchema.shape({
        branchName: Yup.string().required('Branch Name is required'),
        accountType: Yup.string().required('Account Type is required'),
        holderName: Yup.string().required('Account Holder Name is required'),
  });

  // Handle adding new account to the list
  const handleAddAccount = async (values) => {
    setLoading(true)
    const res = await fetcher.post(ApiUrls.LEAD_SAVE_BANK_DETAILS, values);
    if (res?.status === 200) {
      setAccounts([...accounts, res.response]);
            setShowAddBankView(false)
      formik.resetForm();
      setShowAdditionalFields(!showAdditionalFields);
    }
    setLoading(false)
  };

  const handleFetchBankDetail = async (values) => {
    setLoading(true)
    const location = await getLocation();
    const payload = {
      userId: userData.leadUserId,
      accountNumber: values.accountNumber,
      ifsc: values.ifsc,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    const verifyRes = await fetcher.post(ApiUrls.VERIFY_BANK_ACC, payload);
    if (verifyRes?.status === 200 && verifyRes.response.status === 200) {
            formik.setFieldValue('holderName', verifyRes.response.response.data.payee.name);
            formik.setFieldError('isValid', '');
            formik.setFieldTouched('isValid', false, true);
      setShowAdditionalFields(true);
    } else {
            formik.setFieldError('isValid', verifyRes.response.response.message || verifyRes.response.message);
      setShowAdditionalFields(false);
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: newAccount,
    validationSchema: showAdditionalFields ? bankDetailsSchema : initialSchema,
    onSubmit: (values) => {
      if (showAdditionalFields) {
        handleAddAccount(values);
      } else {
        handleFetchBankDetail(values);
      }
        }
  });
  // Handle setting primary account
  const handleSetPrimary = (index) => {
    setPrimaryAccount(index);
  };

  const handleSubmit = async () => {
    setLoading(true)
        const resOtp = await fetcher.get(`${ApiUrls.LEAD_UPDATE_REGISTER_STEP}/${LeadSteps.banking}`);
    if (resOtp?.status === 200) {
      nextStep(4);
    } else {
      alert(resOtp?.message);
    }
    //  alert('Details submitted successfully.');
    setLoading(true)
  };
  const handelAddBankView = () => {
    if (accounts.length >= 10) {
      return;
    }
        setShowAddBankView(true)
    }

  const fetchLeadRegisterDetails = async () => {
    setLoading(true)
    const res = await fetcher.get(ApiUrls.LEAD_REGISTER_DETAIL);
    if (res?.status === 200 && res?.response?.leadBankDetails?.length) {
      setAccounts(res.response.leadBankDetails);
    } else {
      setShowAddBankView(true);
    }
    setLoading(false)
  };
  // Handle adding new account to the list
  const setPrimaryAccount = async (index) => {
    setLoading(true)
    const selectedAccount = accounts[index];
    const payload = {
        id: selectedAccount.id
    };
    const res = await fetcher.post(ApiUrls.LEAD_SET_PRIMARY_ACCOUNT, payload);
    if (res?.status === 200) {
      //Account is set as Primary
      setPrimaryAccountIndex(index);
      fetchLeadRegisterDetails();
    }else{
      //Try Again
    }
    setLoading(false)
  };
  useEffect(() => {
    //fetchLeadRegisterDetails();
    if (userData.leadBankDetails && userData.leadBankDetails.length > 0) {
      setAccounts(userData.leadBankDetails);
        }
        else {
      setShowAddBankView(true);
    }
  }, []);

  return (
    <>
            <div className={` ${useronboard.confirm_details} ${useronboard.complete_registration}`}>
            {loading && <Loader />}
                <div className={`${global.row} ${useronboard.confirm_details_heading} ${global.mobile_d_flex}`}>
          <div className={global.col_8}>
            <h3>Add Bank Account</h3>
          </div>
                    <div className={`${global.col_4} ${global.pos_rel} ${global.text_right}`}>
            <input
                            type='submit'
              className={useronboard.add_bank_account}
              value="+ Add"
              onClick={handelAddBankView}
              disabled={accounts.length >= 10}
            />
          </div>
        </div>

        {accounts.map((account, index) => (
          <div key={index} className={useronboard.bank_account_details}>
            <div className={useronboard.header}>
              <div className={global.row}>
                <div className={global.col_s_2}>Bank Name</div>
                <div className={global.col_s_2}>A/C Number & IFSC</div>
                <div className={global.col_s_2}>Branch</div>
                <div className={global.col_s_2}>Account Type</div>
                <div className={global.col_s_2}>Account Holder Name</div>
                <div className={global.col_s_2}>Set Primary for settlement</div>
              </div>
            </div>
            <div className={useronboard.body}>
              <div className={global.row}>
                <div className={global.col_s_2}>
                  <span className={useronboard.logo}>
                                        <img src={banklogo} alt='' />
                  </span>
                  {account.bankName}
                </div>
                <div className={global.col_s_2}>
                  {account.accountNumber} <br /> {account.ifsc}
                </div>
                                <div className={global.col_s_2}>
                                    {account.branchName}
                                </div>
                                <div className={global.col_s_2}>
                                    {account.accountType}
                                </div>
                                <div className={global.col_s_2}>
                                    {account.holderName}
                                </div>
                <div className={global.col_s_2}>
                  <label className={formmodule.radio_button}>
                    <i className={formmodule.label_text}>&nbsp;</i>
                    <input
                      type="radio"
                      checked={account.isPrimary === true}
                      onChange={() => handleSetPrimary(index)}
                    />
                    <span className={formmodule.checkmark}></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}

        {showAddBankView && (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className={useronboard.confirm_details_form}>
                                <div className={`${global.col_s_6} ${useronboard.bank_details}`}>
                  <h4>Add New Bank</h4>
                </div>
              </div>

              <div className={global.row}>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Bank Name</label>
                    <input
                      name="bankName"
                      className={global.form_control}
                      placeholder="Bank Name"
                      value={formik.values.bankName}
                      onChange={formik.handleChange}
                    />
                                        {formik.errors.bankName && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.bankName}</div>}
                  </div>
                </div>

                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Account Number</label>
                    <input
                      name="accountNumber"
                      className={global.form_control}
                      type="number"
                      placeholder="Account Number"
                      value={formik.values.accountNumber}
                      onChange={formik.handleChange}
                      onKeyPress={(e) => {
                        // Allow only numeric characters (0-9) and backspace
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    {formik.errors.accountNumber && (
                      <div
                        className={`${global.error_message} ${global.text_right}`}
                      >
                        {formik.errors.accountNumber}
                      </div>
                    )}
                  </div>
                </div>

                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>IFSC Code</label>
                    <input
                      name="ifsc"
                      className={global.form_control}
                      placeholder="IFSC Code"
                      value={formik.values.ifsc}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        formik.handleChange(e);
                      }}
                    />

                    {formik.errors.ifsc && (
                      <div
                        className={`${global.error_message} ${global.text_right}`}
                      >
                        {formik.errors.ifsc}
                      </div>
                    )}
                  </div>
                </div>

                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>&nbsp;</label>
                    <button
                      type="submit"
                      className={
                        showAdditionalFields
                          ? global.submit_seconday_btn
                          : global.submit_primary_btn
                      }
                      disabled={showAdditionalFields}
                    >
                      Submit
                    </button>
                  </div>
                  {formik.errors.isValid && (
                    <div
                      className={`${global.error_message} ${global.text_right}`}
                    >
                      {formik.errors.isValid}
                    </div>
                  )}
                </div>
              </div>

              {showAdditionalFields && (
                <>
                  <div className={global.row}>
                    <div className={global.col_s_3}>
                      <div className={global.form_group}>
                        <label>Branch Name</label>
                        <input
                          name="branchName"
                          className={global.form_control}
                          placeholder="Branch Name"
                          value={formik.values.branchName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.branchName && (
                          <div
                            className={`${global.error_message} ${global.text_right}`}
                          >
                            {formik.errors.branchName}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={global.col_s_3}>
                      <div className={global.form_group}>
                        <label>Account Type</label>
                        <select
                          name="accountType"
                          className={global.form_control}
                          value={formik.values.accountType}
                          onChange={formik.handleChange}
                        >
                          <option value="">Select</option>
                          <option value="Saving">Saving Account</option>
                          <option value="Current">Current Account</option>
                        </select>
                        {formik.errors.accountType && (
                          <div
                            className={`${global.error_message} ${global.text_right}`}
                          >
                            {formik.errors.accountType}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={global.col_s_3}>
                      <div className={global.form_group}>
                        <label>Account Holder Name</label>
                        <input
                          name="holderName"
                          className={global.form_control}
                          placeholder="Account Holder Name"
                          value={formik.values.holderName}
                          onChange={formik.handleChange}
                          readOnly
                        />
                        {formik.errors.holderName && (
                          <div
                            className={`${global.error_message} ${global.text_right}`}
                          >
                            {formik.errors.holderName}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={global.col_s_3}>
                      <div
                        className={`${global.form_group} ${global.mar_top_28}`}
                      >
                        <button
                          type="submit"
                          className={
                            formik.isValid
                              ? global.submit_primary_btn
                              : `${global.submit_seconday_btn} `
                          }
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </>
        )}

        {/* Final Submit */}
        <div className={useronboard.submit_text_bg_}>
          <input
            type="submit"
            className={`${global.submit_primary_btn} ${global.display_inline_blk}`}
            value="Submit"
            onClick={handleSubmit}
          />
          <span className={global.info_message}>
            <i className={global.info_icon}></i>{" "}
            <span className={global.info_hide}>
              You can enter the details of up to 10 bank accounts.
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default AddBankAccount;
