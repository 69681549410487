import React from 'react';
import global from '../../assets/scss/global.module.scss';
import UserRegistrations from './UserRegistrations.module.scss';

const AadharAttempt = ({ unsuccessfulAttempt, aadharPanMismatch, onClose }) => {
  return (
    <>
      <div className={global.overlay}>  
        <div className={UserRegistrations.opt_screen}>
          {unsuccessfulAttempt && (
            <>
              <div className={global.text_center}>
                <i className={global.aadhar_attempt_unsuccessful_icon}></i>
              </div>
              <h2>Attempt Reached!</h2>
              <div className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}>
                You've reached the maximum number of PAN number attempts. Please try again in 24 hours.
              </div>
              <div className={`${global.form_group} ${global.mar_top_8}`}>
                <input type='submit' value='Submit' onClick={onClose} className={global.submit_primary_btn} />
              </div>
            </>
          )}
          
          {aadharPanMismatch && (
            <>
              <div className={global.text_center}>
                <i className={global.aadhar_pan_mismatch_icon}></i>
              </div>
              <h2>PAN & Aadhaar are mismatched</h2>
              <div className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}>
                Re-enter your PAN card number because it doesn't match your Aadhaar card.
              </div>
              <div className={`${global.form_group} ${global.mar_top_8}`}>
                <input type='submit' value='Submit' onClick={onClose}  className={global.submit_primary_btn} />
              </div>
            </>               
          )}
        </div> 
      </div>
    </>
  );
};

export default AadharAttempt;
